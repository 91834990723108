/* 全局变量 */

$bgw:#FFF;
$bgm:#FFF;
$cm:#333;
$main-blue:#024a96;
$blue:#0093d9;
$hover-color:#35bbec;
$orange:#ef7826;
$green:#5CB531;
$font-family:"Montserrat-Regular", "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
$main-width:62.5%;

$space1:3%;
$space2:5%;

$navInnerWidth:1772;

// @function scaleHeight($height,$parentWidth){
//     @return percentage($height,$parentWidth);
// }

@function div($a,$b){
    @return $a/$b;
}

@mixin font($name){
    $path:"./assets/fonts/#{$name}/fonts/#{$name}";
    @font-face {
        font-family: $name;
        src: url('#{$path}.eot');
        src: url('#{$path}.woff') format('woff'), 
         url('#{$path}.ttf') format('truetype'),
         url('#{$path}.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }
}


/****  移动端参数  ****/
$mobileWidth:750;
$mobileMainWidth:90%;
$mobileInnerWidth:$mobileWidth*0.9;
$mobileFontSize:7.5;

@function px2Em($px,$base){
  @return $px/$base*1em;
}

@function fs($px){
   @return $px*10/$mobileWidth*1rem;
}

// @function toRem($px){
//     @return $px*10/$mobileWidth*1rem;
// }

.nav,
.nav-inner {
    height: 99px;
}

.nav-inner {
    font-family: "Montserrat-SemiBold";
    position: fixed;
    z-index : 5;
    top     : 0;
    width   : 100%;
    font-size:21/19.2*1em;
    border-bottom:1px solid #b2b2b2;
    background-color:#FFF;

    // >.logo {
    //     display        : block;
    //     height         : 2em;
    //     width:20%;
    //     max-width          : 194px;
    //     background     : none no-repeat 0 50%;
    //     background-size: contain;
    // }
}

.nav-inner {
    >.mw {
        width:percentage($navInnerWidth/1920);
        height     : 100%;
        margin:0 auto;
        align-items: center;

        // pc
        >.logo {
            display        : block;
            height         : 100%;
            width          : percentage(194/$navInnerWidth);
            max-width:194px;
            background     : none no-repeat 0 50%;
            background-size: contain;
        }

    }
}

.is-pc {
    .nav-list {
        white-space    : nowrap;
        justify-content: flex-end;
        margin-left    : 10%;   
        text-align: center;     

        >li {
            position: relative;
            padding : 0 1em;
            height  : 100%;
            flex:1;

            >a {
                position: relative;
                display : block;
                height  : 100%;
                padding: 0 .5em;
                transition:.7s;

                &:hover,
                &.active {
                   color:#FFF;
                   background-color:$blue;
                }

                &.active {
                   pointer-events: none;
                   cursor: default;
                }
            }

        }
    }

    // >.nav-inner {
    //     transition      : .7s;
    // }



    &.is-fold {

        // .logo{
        //     display:none;
        // }
        >.nav-inner {
            color:#333;
            background-color:#FFF;
            box-shadow: 0 0 10px #CCC;
        }

        .nav-bottom {
            margin-top : 1em;
            height     : 4em;
            line-height: 4em;

            .logo-white {
                display        : block;
                height         : 100%;
                width          : 20em;
                background     : none no-repeat 0 50%;
                background-size: contain;
            }
        }

        .nav-list>li>a:after {
            background-color:#8bb8f1;
        }
    }
}



@media (max-width:1160px) {
    .nav.is-pc {
        .nav-list {
            font-size: 16px;
        }
    }
}
