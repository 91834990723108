/* 全局变量 */

$bgw:#FFF;
$bgm:#FFF;
$cm:#333;
$main-blue:#024a96;
$blue:#0093d9;
$hover-color:#35bbec;
$orange:#ef7826;
$green:#5CB531;
$font-family:"Montserrat-Regular", "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
$main-width:62.5%;

$space1:3%;
$space2:5%;

$navInnerWidth:1772;

// @function scaleHeight($height,$parentWidth){
//     @return percentage($height,$parentWidth);
// }

@function div($a,$b){
    @return $a/$b;
}

@mixin font($name){
    $path:"./assets/fonts/#{$name}/fonts/#{$name}";
    @font-face {
        font-family: $name;
        src: url('#{$path}.eot');
        src: url('#{$path}.woff') format('woff'), 
         url('#{$path}.ttf') format('truetype'),
         url('#{$path}.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }
}


/****  移动端参数  ****/
$mobileWidth:750;
$mobileMainWidth:90%;
$mobileInnerWidth:$mobileWidth*0.9;
$mobileFontSize:7.5;

@function px2Em($px,$base){
  @return $px/$base*1em;
}

@function fs($px){
   @return $px*10/$mobileWidth*1rem;
}

// @function toRem($px){
//     @return $px*10/$mobileWidth*1rem;
// }

.footer-view {
    position: relative;
    z-index:3;
    background-color: $main-blue;

    >.inner {
        width    : percentage($navInnerWidth/1920);
        font-size: 22/19.2*1em;
        margin   : 0 auto;
        color    : #FFF;
        padding  : 1em 0;

        >.m {
            background-size: contain;
            $icons         : ("arrow":1, "f":2, "bird":3, "in":4, "weixin":5, "c":6, "line":7);

            @each $key,
            $index in $icons {
                &:nth-child(#{$index}) {
                    background-image:url(../assets/images/footer/#{$key}.svg);
                }
            }

        }

        >span.m {
            padding-right      : 3em;
            background-position: 100% 50%;
            background-size    : auto 1em;
        }

        >a.m {
            display        : inline-block;
            width          : 3em;
            height         : 3em;
            vertical-align : middle;
            margin-left    : .5em;
            border-radius  : 3px;
            background-size: auto 60%;
            transition     : .7s;

            &:hover {
                background-color: rgba(255, 255, 255, .3);
            }

            &.weixin {
                position: relative;

                >div {
                    position        : absolute;
                    width           : 160px;
                    bottom          : 100%;
                    left            : 50%;
                    margin-left     : -90px;
                    margin-bottom   : 10px;
                    border          : 5px solid $blue;
                    border-radius   : 10px;
                    visibility      : hidden;
                    opacity         : 0;
                    transform       : translate3d(0, -20px, 0);
                    background-color: #FFF;
                    transition      : .5s;

                    >img {
                        display      : block;
                        width        : 100%;
                        border-radius: 5px;
                    }

                    &:after {
                        position        : absolute;
                        content         : "";
                        height          : 0;
                        border          : 15px solid transparent;
                        border-top-color: $blue;
                        left            : 50%;
                        top             : 100%;
                        margin-left     : -12px;
                    }
                }

                &:hover {
                    >div {
                        visibility: visible;
                        opacity   : 1;
                        transform : translate3d(0, 0, 0);
                    }
                }
            }
        }
    }

}

@media (max-width:900px) {
    .footer-view {
        >.inner {
            display:flex;           
            width    :percentage($mobileInnerWidth/$mobileWidth);
            font-size: fs(24);

           >span.m{
               margin-right:auto;
               padding-right:2.5em;
           }

            >a.m{
                
                width:2.2em;
                height:2.2em;
                

                &.weixin{
                  
                    >div{
                        bottom:120%;
                    }

                    &:active{
                        >div {
                            visibility: visible;
                            opacity   : 1;
                            transform : translate3d(0, 0, 0);
                        }
                    }
                }
            }
        }
    }
}