/* 全局变量 */

$bgw:#FFF;
$bgm:#FFF;
$cm:#333;
$main-blue:#024a96;
$blue:#0093d9;
$hover-color:#35bbec;
$orange:#ef7826;
$green:#5CB531;
$font-family:"Montserrat-Regular", "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
$main-width:62.5%;

$space1:3%;
$space2:5%;

$navInnerWidth:1772;

// @function scaleHeight($height,$parentWidth){
//     @return percentage($height,$parentWidth);
// }

@function div($a,$b){
    @return $a/$b;
}

@mixin font($name){
    $path:"./assets/fonts/#{$name}/fonts/#{$name}";
    @font-face {
        font-family: $name;
        src: url('#{$path}.eot');
        src: url('#{$path}.woff') format('woff'), 
         url('#{$path}.ttf') format('truetype'),
         url('#{$path}.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }
}


/****  移动端参数  ****/
$mobileWidth:750;
$mobileMainWidth:90%;
$mobileInnerWidth:$mobileWidth*0.9;
$mobileFontSize:7.5;

@function px2Em($px,$base){
  @return $px/$base*1em;
}

@function fs($px){
   @return $px*10/$mobileWidth*1rem;
}

// @function toRem($px){
//     @return $px*10/$mobileWidth*1rem;
// }

.nav.is-mobile,
.nav.is-mobile>.nav-inner {
    height: 120/$mobileWidth*100vw;
}

.nav.is-mobile {
    >.nav-inner {
        font-size: fs(18);

        >.mw {
            width: percentage($mobileInnerWidth/$mobileWidth);

            >.apply-wrap {
                margin-left: 1em;
                display:flex;
                align-items: center;

                >a {
                    display         : inline-block;
                    padding:0 .5em;
                    text-align      : center;
                    color           : #FFF;
                    white-space: nowrap;
                    background-color: $orange;
                }

                &:before {
                    display        : inline-block;
                    content        : "";
                    height         : 1em;
                    width:1em;
                    margin-right     : .5em;
                    background     : url(../assets/images/program/arrow.svg) no-repeat 0 50%;
                    background-size: contain;
                }

            }

            >.menu-btn {
                width          : percentage(80/$mobileInnerWidth);
                height         : 100%;
                background     : url(../assets/images/nav/menu.svg) no-repeat 50% 50%;
                background-size: contain;
  
                &.on{
                    background-image:url(../assets/images/nav/menu-active.svg);
                    background-size:80%;
                }
            }
        }

        .logo {
            width: percentage(196/$mobileInnerWidth);
        }
    }

    >.menu-layer {
        position        : fixed;
        left            : 0;
        top             : 0;
        z-index         : 4;
        width           : 100%;
        height          : 100%;
        box-sizing      : border-box;
        font-size       : fs(36);
        padding-top     : 120/$mobileWidth*100vw;
        visibility      : hidden;
       //  opacity         : 0;
        transform       : translate3d(0, -100%, 0);
        background:#FFF url(../assets/images/apply/dialog-bg2.svg) no-repeat 100% 100%;
        background-size:80% auto;
        transition      : .7s ease-in-out;
        touch-action: none;
        
        >ul{
            margin-top:18%;
        }

        li {
            margin: 1em 0;
        }

        a {
            display: block;
            color  : $main-blue;
        }

        &.on {
            visibility: visible;
           // opacity   : 1;
            transform : translate3d(0, 0, 0);
        }
    }
}